const  pubsdata =  [
    {
	id:9,
	title:"Out of sight, out of mind: UI design and the inhibition of mental models of security",
	author:"Eric Spero and Robert Biddle",
	venue:"New Security Paradigms Workshop 2020 (NSPW)",
	publisher:"ACM",
	doi:"in press",
	date:"August 2020",
	type:"peerconf"
    },
    {
	id:8,
	title:"Home and away: Design patterns for supporting end-user security",
	author:"Eric Spero and Robert Biddle",
	venue:"European Conference on Pattern Languages of Programs 2020 (EuroPLoP)",
	publisher:"ACM",
	doi:"https://doi.org/10.1145/3424771.3424780",
	date:"July 2020",
	type:"peerconf"
    },
    {
	id:7,
	title:"Control and understanding in malware and legitimate software",
	author:"Eric Spero, Milica Stojmenović, Sonia Chiasson, and Robert Biddle",
	venue:"2019 APWG Symposium on Electronic Crime Research (eCrime)",
	publisher:"IEEE",
	doi:"https://doi.org/10.1109/eCrime47957.2019.9037597",
	date:"November 2019",
	type:"peerconf"
    },
    {
	id:6,
	title:"Security begins at home: Everyday security behaviour and lessons for cybersecurity research",
	author:"Eric Spero and Robert Biddle",
	venue:"26th Conference on Pattern Languages of Programs (PLoP)",
	publisher:"ACM",
	doi:"in press",
	date:"October 2019",
	type:"peerconf"
    },
    {
	id:5,
	title:"Website identity notification: Testing the simplest thing that could possibly work",
	author:"Milica Stojmenović, Eric Spero, Temitayo Oyelowo, and Robert Biddle",
	venue:"26th Conference on Pattern Languages of Programs (PLoP)",
	publisher:"IEEE",
	doi:"https://dx.doi.org/10.1109/PST47121.2019.8949048",
	date:"August 2019",
	type:"peerconf"
    },
    {
	id:4,
	title:"Mixed pictures: Mental models of malware",
	author:"Eric Spero, Milica Stojmenović, Zahra Hassanzadeh, Sonia Chiasson, and Robert Biddle",
	venue:"17th International Conference on Privacy, Security and Trust (PST)",
	publisher:"IEEE",
	doi:"https://doi.org/10.1109/PST47121.2019.8949030",
	date:"August 2019",
	type:"peerposter"
    },
    {
	id:3,
	title:"Learning with trees: A non-linear e-textbook format for deep learning",
	author:"Eric Spero, Milica Stojmenović, Ali Arya, and Robert Biddle",
	venue:"21st International Conference on Human-Computer Interaction (HCII)",
	publisher:"Springer",
	doi:"https://dx.doi.org/10.1007/978-3-030-21814-0_31",
	date:"July 2019",
	type:"peerconf"
    },
    {
	id:2,
	title:"Helping users secure their data by supporting mental models of VeraCrypt",
	author:"Eric Spero, Milica Stojmenović, and Robert Biddle",
	venue:"21st International Conference on Human-Computer Interaction (HCII)",
	publisher:"Springer",
	doi:"https://dx.doi.org/10.1007/978-3-030-23522-2_27",
	date:"July 2019",
	type:"peerposter"
    },
    {
	id:1,
	title:"Creating a non-linear textbook format to facilitate deep learning",
	author:"Eric Spero, Milica Stojmenović, Ali Arya, and Robert Biddle",
	venue:"13th International Technology, Education and Development Conference (INTED)",
	publisher:"IATED",
	doi:"https://dx.doi.org/10.21125/inted.2019.0753",
	date:"March 2019",
	type:"nopeerabs"
    }
];

export default pubsdata;
