<template>
    <div class="pubproj">
	<ul>
	    <li><b> {{ title }} </b></li>
	    <li> {{ author }} </li>
	    <li><i> {{ venue }} </i></li>
	    <li>
		<a v-if="doi !== 'in press'" :href="doi" class="doi link" target="_blank"> {{ publisher }} </a>
		<span v-else> {{ publisher }} ({{ doi }}) </span>
	    </li>

	    <li> {{ date }} </li>
	</ul>

    </div>
</template>

<script>
 export default {
     name: 'Pubcomponent',
     props: {
	 title: String,
	 author: String,
	 venue: String,
	 publisher: String,
	 doi: String,
	 date: String
     }
 }

</script>
