<template>
    <div class="body">
	<div class="pubblock">
	    <h3> Peer-reviewed conferences: </h3>

	    <div v-for="pub in pubsdata" v-bind:key="pub" class="pubitem">
		<div v-if="pub.type==='peerconf'">
		    <Publication v-bind="pub"> </Publication>
		</div>
	    </div>
	</div>
	<div class="pubblock">
	    <h3> Peer-reviewed posters: </h3>

	    <div v-for="pub in pubsdata" v-bind:key="pub" class="pubitem">
		<div v-if="pub.type==='peerposter'">
		    <Publication v-bind="pub"> </Publication>
		</div>
	    </div>
	</div>
    </div>
</template>

<script>
 import Publication from '@/components/Publication.vue'
 import pubsdata from '@/data/pubs.js'

 export default {
     name: 'Publication2',
     components: {
	 Publication
     },
     data() {
	 return {
	     pubsdata
	 }
     }
 }
</script>
<style>

 .pubblock{
     margin-bottom: 5ex;
 }

</style>
